// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Switcher-module--cds--assistive-text--15c78";
export var cdsHideFeedback = "Switcher-module--cds--hide-feedback--cebd2";
export var cdsLayoutConstraintDensity__defaultCondensed = "Switcher-module--cds--layout-constraint--density__default-condensed--52396";
export var cdsLayoutConstraintDensity__defaultNormal = "Switcher-module--cds--layout-constraint--density__default-normal--aadbd";
export var cdsLayoutConstraintDensity__maxCondensed = "Switcher-module--cds--layout-constraint--density__max-condensed--bdcb0";
export var cdsLayoutConstraintDensity__maxNormal = "Switcher-module--cds--layout-constraint--density__max-normal--de3da";
export var cdsLayoutConstraintDensity__minCondensed = "Switcher-module--cds--layout-constraint--density__min-condensed--bf56f";
export var cdsLayoutConstraintDensity__minNormal = "Switcher-module--cds--layout-constraint--density__min-normal--9cefc";
export var cdsLayoutConstraintSize__default2xl = "Switcher-module--cds--layout-constraint--size__default-2xl--140a8";
export var cdsLayoutConstraintSize__defaultLg = "Switcher-module--cds--layout-constraint--size__default-lg--c28a5";
export var cdsLayoutConstraintSize__defaultMd = "Switcher-module--cds--layout-constraint--size__default-md--c01d9";
export var cdsLayoutConstraintSize__defaultSm = "Switcher-module--cds--layout-constraint--size__default-sm--b3d8f";
export var cdsLayoutConstraintSize__defaultXl = "Switcher-module--cds--layout-constraint--size__default-xl--ff58d";
export var cdsLayoutConstraintSize__defaultXs = "Switcher-module--cds--layout-constraint--size__default-xs--e8faa";
export var cdsLayoutConstraintSize__max2xl = "Switcher-module--cds--layout-constraint--size__max-2xl--fcbcf";
export var cdsLayoutConstraintSize__maxLg = "Switcher-module--cds--layout-constraint--size__max-lg--b4f59";
export var cdsLayoutConstraintSize__maxMd = "Switcher-module--cds--layout-constraint--size__max-md--71e24";
export var cdsLayoutConstraintSize__maxSm = "Switcher-module--cds--layout-constraint--size__max-sm--a0558";
export var cdsLayoutConstraintSize__maxXl = "Switcher-module--cds--layout-constraint--size__max-xl--e1db3";
export var cdsLayoutConstraintSize__maxXs = "Switcher-module--cds--layout-constraint--size__max-xs--c659e";
export var cdsLayoutConstraintSize__min2xl = "Switcher-module--cds--layout-constraint--size__min-2xl--99d9c";
export var cdsLayoutConstraintSize__minLg = "Switcher-module--cds--layout-constraint--size__min-lg--fd08f";
export var cdsLayoutConstraintSize__minMd = "Switcher-module--cds--layout-constraint--size__min-md--c6ab5";
export var cdsLayoutConstraintSize__minSm = "Switcher-module--cds--layout-constraint--size__min-sm--c8900";
export var cdsLayoutConstraintSize__minXl = "Switcher-module--cds--layout-constraint--size__min-xl--7ccf6";
export var cdsLayoutConstraintSize__minXs = "Switcher-module--cds--layout-constraint--size__min-xs--fd1a2";
export var cdsLayoutDensityCondensed = "Switcher-module--cds--layout--density-condensed--a34e7";
export var cdsLayoutDensityNormal = "Switcher-module--cds--layout--density-normal--a8b98";
export var cdsLayoutSize2xl = "Switcher-module--cds--layout--size-2xl--c57e2";
export var cdsLayoutSizeLg = "Switcher-module--cds--layout--size-lg--9c19a";
export var cdsLayoutSizeMd = "Switcher-module--cds--layout--size-md--af449";
export var cdsLayoutSizeSm = "Switcher-module--cds--layout--size-sm--5f8f2";
export var cdsLayoutSizeXl = "Switcher-module--cds--layout--size-xl--7899a";
export var cdsLayoutSizeXs = "Switcher-module--cds--layout--size-xs--84a0e";
export var cdsShowFeedback = "Switcher-module--cds--show-feedback--d36b8";
export var cdsSkeleton = "Switcher-module--cds--skeleton--e73b9";
export var cdsVisuallyHidden = "Switcher-module--cds--visually-hidden--edc08";
export var divider = "Switcher-module--divider--b52c6";
export var link = "Switcher-module--link--62a5a";
export var linkDisabled = "Switcher-module--link--disabled--21858";
export var nav = "Switcher-module--nav--99069";
export var open = "Switcher-module--open--775bd";